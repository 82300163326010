import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';

import MainHome from "./Main/MainHome.jsx";
import MainIntegration from "./Main/MainIntegration.jsx";
import MainCallFieldScript from "./Main/MainCallFieldScript.jsx";

import { ConfigurationApiCRMScriptsGet, ConfigurationApiIntegrationGet } from "../ExternalApi/ConfigurationApi.js";
import { SetLanguageId, GetLanguageId, GetLanguageCaption, ResetLanguageId, Translate } from "./Translator";

import CloudCTIPng from '../Assets/CloudCTI.png';
import WebConfiguratorHeaderPng from '../Assets/WebConfiguratorHeader.png';
import SignOutPng from '../Assets/SignOut.png';
import UserPng from '../Assets/User.png';
import HomePng from '../Assets/Home.png';
import HomeSelectedPng from '../Assets/HomeSelected.png';
import IntegrationPng from '../Assets/Integration.png';
import IntegrationDisabledPng from '../Assets/IntegrationDisabled.png';
import IntegrationSelectedPng from '../Assets/IntegrationSelected.png';
import IntegrationDisabledSelectedPng from '../Assets/IntegrationDisabledSelected.png';

import "../css/Main.css";

//Subclasses

const Main = (props) => {

  const mHistory = useHistory();

  //debug
  //const mSecurityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJhbmRyZS52ZW5lbWFAZ21haWwuY29tIiwiU2lnbmluUGFzc3dvcmQiOiJ4V2ZFdmFSVFVZWVhUdTZwNStId0s3WFIvQlZ2R2RLSnROc1l3RHV4WDNTUmFjMUpvN1ZacmZFQjEvSnVIelc0OXdteTBEaGNiR0FhTjZBd1MxaHRwMC8wT0x1N3pNc2lnU0VrN3JkMlJ3ejJoUE1xM0FVY1BFd0VhMHE3dmpaRiIsIlNpZ25pbkFwcGxpY2F0aW9uIjoiUmVjb2duaXRpb25Db25maWd1cmF0aW9uVG9vbCIsIlNpZ25pblByb2R1Y3QiOiJDbG91ZENUSSIsIlRlbmFudEd1aWQiOiJUZW5hbnRfNjE4YmNhYmItNDZkYS00MmViLWFmOTQtOWQ4NWMyNzY3ZTM0IiwiVGVuYW50TmFtZSI6IktleWxpbmsiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfMTI1ZWMzODctYzYyOS00MmNjLWJjMmItZTU2MTI1OTMxZjMxIiwiVXNlck5hbWUiOiJBbmRyZSBWZW5lbWEiLCJVc2VySXNBZG1pbiI6IjEiLCJVc2VySXNUZW5hbnRBZG1pbiI6IjEiLCJFbWFpbCI6ImFuZHJlLnZlbmVtYUBnbWFpbC5jb20iLCJMaWNlbmNlTmFtZSI6IkNsb3VkQ1RJIFVzZXIiLCJSZWNvZ25pdGlvbkFuZFNjcmVlbnBvcCI6IjEiLCJSb2xlcyI6IlVzZXIsQWRtaW5pc3RyYXRvciIsImV4cCI6MTcyMzI5OTUwOCwiaXNzIjoic2lnbmluLXZhIiwiYXVkIjoiQ2xvdWRDVEkyMDIyIn0.dsQVAOgBzacuU7wSBA9vlojXelp_xYOmoUirxcj8lsI";
  const mSecurityToken = Cookies.get("webconfigurator")

  const [mUserName, SetUserName] = useState("")
  const [mTenantName, SetTenantName] = useState("")

  const [mRepainter, SetRepainter] = useState(false)

  const [mPredefinedScripts, SetPredefinedScripts] = useState([])  
  const [mIntegrations, SetIntegrations] = useState([]) 

  const [mSelectedIntegrationGuid, SetSelectedIntegrationGuid] = useState(window.sessionStorage.getItem("SelectedIntegrationGuid"));
  const [mSelectedCallfieldScriptIndex, SetSelectedCallfieldScriptIndex] = useState(parseInt(window.sessionStorage.getItem("SelectedCallfieldScriptIndex")));

  useEffect(() => {
    const DecodedToken = jwtDecode(mSecurityToken)
    SetUserName(DecodedToken.UserName)
    SetTenantName(DecodedToken.TenantName)
   
    ResetLanguageId()
    ConfigurationApiCRMScriptsGet(mSecurityToken).then(result => { SetPredefinedScripts(result) })    
    ConfigurationApiIntegrationGet(mSecurityToken).then(result => { SetIntegrations(result) })

    //Dit zet ik voor de development even uit
    const Interval = setInterval(() => RefreshIntegrations(), 60000); 
    return () => clearInterval(Interval);
  }, []); 

  const LanguageChanged = () => {
    SetRepainter(!mRepainter)
  }

  const RefreshIntegrations = () => {
    console.log("Main.RefreshIntegrations")
    ConfigurationApiIntegrationGet(mSecurityToken).then(result => { SetIntegrations(result) })
  }

  const SelectIntegration = (aIntegrationGuid) => {
    SetSelectedCallfieldScriptIndex(null)
    window.sessionStorage.setItem("SelectedCallfieldScriptIndex", "");

    SetSelectedIntegrationGuid(aIntegrationGuid)
    window.sessionStorage.setItem("SelectedIntegrationGuid", aIntegrationGuid);
  }

  const SelectCallFieldScript = (aScriptIndex) => {
    SetSelectedIntegrationGuid(null)
    window.sessionStorage.setItem("SelectedIntegrationGuid", "");

    SetSelectedCallfieldScriptIndex(aScriptIndex)
    window.sessionStorage.setItem("SelectedCallfieldScriptIndex", aScriptIndex);
  }

  const SignOut = () => {
    Cookies.remove('webconfigurator', { domain: '.cloudcti.nl' })
    mHistory.push("/")
  }  

  const OpenPopupFullscreen = (aUrl) => {
    window.open(aUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 1600)/2)  + ',top=' + ((screen.availHeight - 800)/2)  + ',width=1600,height=800')
  }

  if (!mSecurityToken || !mPredefinedScripts || !mIntegrations)
  {
    return (
      <div className="loading-outer">
        <div className="loading-inner">
          <FontAwesomeIcon icon={faSpinner} spin /> {Translate("Loading...")}
        </div>
      </div>
    );       
  }
  else
  {
    if (localStorage.getItem("SelectedIntegrationGuid") !== "")
    {
      SelectIntegration(localStorage.getItem("SelectedIntegrationGuid"))
      localStorage.setItem("SelectedIntegrationGuid","")
    } 
  
    if ((localStorage.getItem("SelectedCallfieldScript") === "last"))
    {
      SelectCallFieldScript(100)
      localStorage.setItem("SelectedCallfieldScript", "")
    }

    //All regular integrations
    const Integrations = mIntegrations.filter(integration => integration.IntegrationGuid !== "DefaultScripts")

    //All callfield scripts
    const DefaultScriptsIntegrations = mIntegrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
    const CallFieldScripts = DefaultScriptsIntegrations && (DefaultScriptsIntegrations.length === 1) ? (DefaultScriptsIntegrations[0].Scripts ?? []) : []
 
    const SelectedIntegration = Integrations.find(integration => integration.IntegrationGuid === mSelectedIntegrationGuid)
    const SelectedCallfieldScript = mSelectedCallfieldScriptIndex === 100 ? CallFieldScripts[CallFieldScripts.length - 1] : (CallFieldScripts.length > mSelectedCallfieldScriptIndex) && (mSelectedCallfieldScriptIndex > -1) ? CallFieldScripts[mSelectedCallfieldScriptIndex] : null

    const BrandingColor = sessionStorage.getItem("BrandingColor")
    const BrandingColorLight = sessionStorage.getItem("BrandingColorLight")

    return (   
      <span>

        <table className="main" cellPadding={0}>        
        <tbody>
        <tr className="pagetop2">
            <td width="*" className="cloudctiicon pagetitle"><img src={WebConfiguratorHeaderPng} className="cloudctiicon" alt="CloudCTI" style={{ backgroundColor: BrandingColor }}/></td>
            <td width="1200" valign="top"/>
            <td width="*" align="right" valign="top"><img src={SignOutPng} className="topbarbuttonicon" onClick={() => SignOut()}/></td>
        </tr>  
        <tr height="10" />
        <tr height="94">
            <td colSpan={3} style={{ backgroundColor: BrandingColorLight }}/>
        </tr>   
        <tr height="10" />
        <tr>
          <td/>
          <td valign="top">
            <br/><br/>

            <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td width="250" valign="top">
                <table cellPadding={20} width="100%" height="100%" className="mainmenu" style={{ backgroundColor: BrandingColor }}>
                <tbody>
                  <tr>
                    <td valign="top">
                      <table cellPadding={10} width="100%" className={mSelectedIntegrationGuid === "" ? "mainmenuitemselected" : "mainmenuitem"} onClick={() => SelectIntegration("")}>
                      <tbody>
                        <tr>
                          <td width="24" align="left" valign="center"><img src={mSelectedIntegrationGuid === "" ? HomeSelectedPng : HomePng} className="mainmenuicon" style={{ backgroundColor: BrandingColor }} /></td>
                          <td width="*" align="left" valign="center">Home</td>
                        </tr>
                      </tbody>
                      </table>

                      <br/>

                      <div className="mainmenusubheader" disabled>{Translate("Integrations")}</div>
                      {Integrations.map((integration, index) => (
                        <table key={index} cellPadding={10} width="100%" className={integration.IntegrationGuid === mSelectedIntegrationGuid ? "mainmenuitemselected" : "mainmenuitem"} onClick={() => SelectIntegration(integration.IntegrationGuid)}>
                        <tbody>
                        <tr>
                          <td width="24" align="left" valign="center">
                            <img src={integration.Enabled ? (integration.IntegrationGuid === mSelectedIntegrationGuid ? IntegrationSelectedPng : IntegrationPng) : (integration.IntegrationGuid === mSelectedIntegrationGuid ? IntegrationDisabledSelectedPng : IntegrationDisabledPng)} style={{ backgroundColor: BrandingColor}} className="mainmenuicon"/>
                          </td>
                          <td width="*" align="left" valign="center" className={integration.Enabled ? "" : (integration.IntegrationGuid === mSelectedIntegrationGuid ? "mainmenudisabledselected" : "mainmenudisabled")}>{integration.ApplicationName}</td>
                        </tr>
                        </tbody>
                        </table>
                      ))}

                      <br/>

                      <div className="mainmenusubheader">{Translate("Scripts")}</div>
                      {CallFieldScripts.map((script, index) => (
                        <table key={index} cellPadding={10} width="100%" className={((index === mSelectedCallfieldScriptIndex) || ((index === CallFieldScripts.length - 1) && (mSelectedCallfieldScriptIndex === 100))) ? "mainmenuitemselected" : "mainmenuitem"} onClick={() => SelectCallFieldScript(index)}>
                        <tbody>
                        <tr>
                          <td width="24" align="left" valign="center">
                            <img src={script.Enabled ? (((index === mSelectedCallfieldScriptIndex) || ((index === CallFieldScripts.length - 1) && (mSelectedCallfieldScriptIndex === 100))) ? IntegrationSelectedPng : IntegrationPng) : (index === mSelectedCallfieldScriptIndex ? IntegrationDisabledSelectedPng : IntegrationDisabledPng)} className="mainmenuicon"/>
                          </td>
                          <td width="*" align="left" valign="center">{script.XmlApplication ? script.XmlApplication : script.Name}</td>
                        </tr>
                        </tbody>
                        </table>
                      ))}
                      <br/>

                      <Button variant="dark" className="addnewbutton" onClick={() => OpenPopupFullscreen("/IntegrationWizard?Action=add&After=&SecurityToken=" + mSecurityToken + "&languageid=" + GetLanguageId())}>{Translate("Add new integration").toUpperCase()}</Button>
                      <Button variant="dark" className="addnewbutton" onClick={() => OpenPopupFullscreen("/ScriptWizard?Action=add&After=&SecurityToken=" + mSecurityToken + "&languageid=" + GetLanguageId())}>{Translate("Add new script").toUpperCase()}</Button>
                    </td>
                    </tr>
                </tbody>
                </table>
              </td>
              <td width="50"></td>
              <td width="900" valign="top">
                {SelectedIntegration ? <MainIntegration SecurityToken={mSecurityToken} UserName={mUserName} Integration={SelectedIntegration} History={mHistory} OnOpenPopupFullscreen={OpenPopupFullscreen} OnIntegrationsChanged={SetIntegrations} OnIntegrationsRefresh={RefreshIntegrations}/> 
                  : SelectedCallfieldScript ? <MainCallFieldScript SecurityToken={mSecurityToken} Scripts={CallFieldScripts} SelectedIndex={mSelectedCallfieldScriptIndex === 100 ? CallFieldScripts.length - 1 : mSelectedCallfieldScriptIndex} PredefinedScripts={mPredefinedScripts} OnOpenPopupFullscreen={OpenPopupFullscreen} OnIntegrationsChanged={SetIntegrations} /> 
                    : <MainHome TenantName={mTenantName} UserName={mUserName} OnLanguageChanged={LanguageChanged} OnSignOut={SignOut}/>}  
              </td>
            </tr>
            </tbody>
            </table>
            
            <br/><br/>
          </td>  
          <td/>
        </tr>
        <tr height="90">
            <td colSpan={3} className="pagefooter" style={{ backgroundColor: BrandingColor }}></td>
        </tr>
        <tr height="50">
            <td/>
            <td className="wizardbottomcloudcti">&#169; 2024 CloudCTI</td>
            <td/>
        </tr>
        </tbody>
        </table>
      </span> 
    );
  }  
}

export default Main;