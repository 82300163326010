export function GetUrlParameter(aUrl, aParamKey)
{
  let Index = aUrl.indexOf("?" + aParamKey + "=")
  if (Index < 1) { Index = aUrl.indexOf("&" + aParamKey + "=") }
    
  if (Index > 0)
  {
    aUrl = aUrl.substring(Index + aParamKey.length + 2)

    Index = aUrl.indexOf("&")
    return (Index > 0) ? aUrl.substring(0,Index) : aUrl
  }
  else
  {
    return ""
  }
}