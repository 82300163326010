import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import IntegrationWizardStepReviewScript from "./IntegrationWizardStepReviewScript.jsx"; //Step 11
import { Translate } from "../Translator";
import { GetDatasourceName } from "../Datasources/Datasources.jsx"
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

const IntegrationWizardStepReview = (props) => {

    useEffect(() => {        
        props.OnNextButtonEnabled(true)
      }, []); 

    return (
        <div>
          <IntegrationWizardStepHeader Header={Translate("Review your integration")}/>

          <table width="100%" cellPadding="0" cellSpacing="0">
          <tbody>
          <tr>
            <td width="200" className="wizardreviewname">{Translate("Application")}</td>
            <td width="*" className="wizardreviewvalue">{props.IntegrationSettings.WizardSettings.application ? props.IntegrationSettings.WizardSettings.application : GetDatasourceName(props.IntegrationSettings.DatasourceType)}</td>
          </tr>
          <tr>
            <td className="wizardreviewname">{Translate("Recognition")}</td>
            <td className="wizardreviewvalue">{props.IntegrationSettings.DatasourceType === "none" ? Translate("No recognition has been configured") : Translate("Recognition from") + " " + props.IntegrationSettings.DatasourceType}</td>
          </tr>
          <tr>
            <td className="wizardreviewname">{Translate("Scripts")}</td>
            <td className="wizardreviewvalue">{props.IntegrationSettings.Scripts.map((script,index) => <IntegrationWizardStepReviewScript key={"script" + index} Script={script}/>)}</td>
          </tr>
          </tbody>
          </table>
        </div>
    );
}

IntegrationWizardStepReview.propTypes = {
  IntegrationSettings: PropTypes.object,
  OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepReview