import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

//Not a standard datasource because CustomSite visibility has to be toggled

const DatasourceZendesk = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false)

    let mAuthType = props.IntegrationSettings.DatasourceSettings.ApiKey ? "ApiKey" : "Password"
    let mAuthField = props.IntegrationSettings.DatasourceSettings.ApiKey ? props.IntegrationSettings.DatasourceSettings.ApiKey : props.IntegrationSettings.DatasourceSettings.Password

    useEffect(() => {
        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        if (aIdentifier === "UserName")
        {
            props.IntegrationSettings.DatasourceSettings.UserName = aNewValue
        }
        else if (aIdentifier === "Url")
        {
            props.IntegrationSettings.DatasourceSettings.Url = aNewValue
        }
        else if (aIdentifier === "AuthType")
        {
            mAuthType = aNewValue
            if (mAuthType === "ApiKey")
            {
                props.IntegrationSettings.DatasourceSettings.ApiKey = mAuthField
                props.IntegrationSettings.DatasourceSettings.Password = ""
            }
            else
            {
                props.IntegrationSettings.DatasourceSettings.ApiKey = ""
                props.IntegrationSettings.DatasourceSettings.Password = mAuthField
            }
        }
        else if (aIdentifier === "AuthField")
        {
            mAuthField = aNewValue
            if (mAuthType === "ApiKey")
            {
                props.IntegrationSettings.DatasourceSettings.ApiKey = mAuthField
                props.IntegrationSettings.DatasourceSettings.Password = ""
            }
            else
            {
                props.IntegrationSettings.DatasourceSettings.ApiKey = ""
                props.IntegrationSettings.DatasourceSettings.Password = mAuthField
            }
        }

        SetReady()
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.UserName 
            && props.IntegrationSettings.DatasourceSettings.Url
            && (props.IntegrationSettings.DatasourceSettings.ApiKey || props.IntegrationSettings.DatasourceSettings.Password))
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    const CustomSite = 
        props.IntegrationSettings.DatasourceSettings.Region === "custom" ? <tr><td width="300">{Translate("Site")}</td><td width="*"><input type="text" size="100" value={props.IntegrationSettings.DatasourceSettings.CustomSite || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "CustomSite")}/></td></tr> :
        <tr></tr>

    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Username").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.UserName || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "UserName")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">URL <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Url || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Url")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Authentication type").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><select name="AuthType" onChange={evt => UpdateDatasourceSetting("AuthType", evt.target.value)} className="wizardinputfield">
                <option value="Password" selected={mAuthField === "Password"}>{Translate("Password")}</option>
                <option value="ApiKey" selected={mAuthField === "ApiKey"}>{Translate("Api key")}</option>
            </select></div>
            <div className="wizardfieldname">{Translate("Authentication value").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={mAuthField || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "AuthField")} className="wizardinputfield"/></div>
        </div>
    );
}

DatasourceZendesk.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceZendesk