import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { Translate } from "../Translator.jsx"

import SelectedHeaderPng from '../../Assets/SelectedHeader.png';
import Step1Png from '../../Assets/Step1.png';
import Step2Png from '../../Assets/Step2.png';
import Step3Png from '../../Assets/Step3.png';
import Step4Png from '../../Assets/Step4.png';
import Step5Png from '../../Assets/Step5.png';

import "../../css/IntegrationWizard.css";

const IntegrationWizardHeader = (props) => {

    const IsWizardHeaderDisabled = (aHeader) => {
        if (aHeader === "Choose preset") { return props.Action === "update" || props.Action === "setcountrycode" || props.Action === "setnotification" || props.Action === "setscripts"}
        else if (aHeader === "CRM configuration") { return props.Action === "setnotification" || props.Action === "setscripts"}
        else if (aHeader === "Call notification") { return props.Action === "setcountrycode" || props.Action === "setscripts"}
        else if (aHeader === "Scripts") { return props.Action === "setcountrycode" || props.Action === "setnotification"}
        else if (aHeader === "Review") { return props.Action === "setcountrycode" || props.Action === "setnotification" || props.Action === "setscripts"}
        else {return false}
    }

    //Determines which step in the top menu is active
    const MenuStep = (props.CurrentStep > 9) ? 5 :
        ((props.CurrentStep > 8) ? 4 :
            ((props.CurrentStep > 7) ? 3 :
                ((props.CurrentStep > 3) ? 2 : 1)))

    //Paint
    
    const BrandingColor = sessionStorage.getItem("BrandingColor")
    const BrandingColorLight = sessionStorage.getItem("BrandingColorLight")

    return ( 

        <table width="100%" height="64" cellSpacing="0" className="wizardheadertable">
        <tbody>
        <tr>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 1) ? "*" : "50")} align="left" valign="center" style={{ backgroundColor: IsWizardHeaderDisabled("Choose preset") ? BrandingColorLight : BrandingColor, color: IsWizardHeaderDisabled("CRM configuration") ? "black" : "white" }}>
                <table width="100%" height="64" cellPadding="6" cellSpacing="0">
                <tbody>
                <tr>
                    <td width="0"></td>
                    <td width="50" height="100%" align="left" valign="center">
                        <img src={Step1Png} className="stepimage" title={Translate("Choose preset")} />
                    </td>
                    {(window.innerWidth > 800) || (MenuStep === 1) ? 
                        <td width="*" height="100%" align="left" valign="center">
                            <span className="stepheadertext">{Translate("Choose preset")}</span>
                        </td> :
                        <td/>
                    }
                </tr>
                </tbody>
                </table>
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 2) ? "*" : "50")} height="100%" align="left" valign="center" style={{ backgroundColor: IsWizardHeaderDisabled("CRM configuration") || (props.CurrentStep < 4) ? BrandingColorLight : BrandingColor, color: IsWizardHeaderDisabled("CRM configuration") || (props.CurrentStep < 4) ? "black" : "white"}}>
                <table width="100%" height="64" cellPadding="6" cellSpacing="0">
                <tbody>
                <tr>
                    <td width="0"></td>
                    <td width="50" height="100%" align="left" valign="center">
                        <img src={Step2Png} className="stepimage" title={Translate("CRM configuration")} />
                    </td>
                    {(window.innerWidth > 800) || (MenuStep === 2) ? 
                        <td width="*" height="100%" align="left" valign="center">
                            <span className="stepheadertext">{Translate("CRM configuration")}</span>
                        </td> :
                        <td/>
                    }
                </tr>
                </tbody>
                </table>                     
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 3) ? "*" : "50")} height="100%" align="left" valign="center" style={{ backgroundColor: IsWizardHeaderDisabled("Call notification") || (props.CurrentStep < 8) ? BrandingColorLight : BrandingColor, color: IsWizardHeaderDisabled("Call notification") || (props.CurrentStep < 8) ? "black" : "white"}}>
                <table width="100%" height="64" cellPadding="6" cellSpacing="0" style={IsWizardHeaderDisabled("Call notification") ? { opacity: 0.25 } : {}}>
                <tbody>
                <tr>
                    <td width="0"></td>
                    <td width="50" height="100%" align="left" valign="center">
                        <img src={Step3Png} className="stepimage" title={Translate("Call notification")} />
                    </td>
                    {(window.innerWidth > 800) || (MenuStep === 3) ?
                        <td width="*" height="100%" align="left" valign="center">
                            <span className="stepheadertext">{Translate("Call notification")}</span>
                        </td> :
                        <td/>
                    }
                </tr>
                </tbody>
                </table>                      
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 4) ? "*" : "50")} height="100%" align="left" valign="center" style={{ backgroundColor: IsWizardHeaderDisabled("Scripts") || (props.CurrentStep < 9) ? BrandingColorLight : BrandingColor, color: IsWizardHeaderDisabled("Scripts") || (props.CurrentStep < 9) ? "black" : "white"}}>
                <table width="100%" height="64" cellPadding="6" cellSpacing="0" style={IsWizardHeaderDisabled("Scripts") ? { opacity: 0.25 } : {}}>
                <tbody>
                <tr>
                    <td width="0"></td>
                    <td width="50" height="100%" align="left" valign="center">
                        <img src={Step4Png} className="stepimage" title={Translate("Scripts")} />
                    </td>
                    {(window.innerWidth > 800) || (MenuStep === 4) ?
                        <td width="*" height="100%" align="left" valign="center">
                            <span className="stepheadertext">{Translate("Scripts")}</span>
                        </td> :
                        <td/>
                    }
                </tr>
                </tbody>
                </table>                     
            </td>
            <td width={(window.innerWidth > 800) ? "20%" : ((MenuStep === 5) ? "*" : "50")} height="100%" align="left" valign="center"style={{ backgroundColor: IsWizardHeaderDisabled("Review") || (props.CurrentStep < 10) ? BrandingColorLight : BrandingColor, color: IsWizardHeaderDisabled("Review") || (props.CurrentStep < 10) ? "black" : "white"}}>
                <table width="100%" height="64" cellPadding="6" cellSpacing="0" style={IsWizardHeaderDisabled("Review") ? { opacity: 0.25 } : {}}>
                <tbody>
                <tr>
                    <td width="50" height="100%" align="left" valign="center">
                        <img src={Step5Png} className="stepimage" title={Translate("Review")} />
                    </td>
                    {(window.innerWidth > 800) || (MenuStep === 5) ?
                        <td width="*" height="100%" align="left" valign="center">
                            <span className="stepheadertext">{Translate("Review")}</span>
                        </td> :
                        <td/>
                    }
                </tr>
                </tbody>
                </table>            
            </td>
        </tr>
        <tr height="9">
            <td align="center">
                {props.CurrentStep < 4 ? <img src={SelectedHeaderPng} className="stepimageselected" style={{ backgroundColor: BrandingColor }} /> : ""}
            </td>
            <td align="center">
                {(props.CurrentStep > 3) && (props.CurrentStep < 8) ? <img src={SelectedHeaderPng} className="stepimageselected" style={{ backgroundColor: BrandingColor }} /> : ""}
            </td>
            <td align="center">
                {props.CurrentStep === 8 ? <img src={SelectedHeaderPng} className="stepimageselected" style={{ backgroundColor: BrandingColor }} /> : ""}
            </td>
            <td align="center">
                {props.CurrentStep === 9 ? <img src={SelectedHeaderPng} className="stepimageselected" style={{ backgroundColor: BrandingColor }} /> : ""}
            </td>
            <td align="center">
                {props.CurrentStep > 9 ? <img src={SelectedHeaderPng} className="stepimageselected" style={{ backgroundColor: BrandingColor }} /> : ""}
            </td>
        </tr>  
        </tbody>
        </table>
    )
}

IntegrationWizardHeader.propTypes = {
    Action: PropTypes.string,
    CurrentStep: PropTypes.number
  }

export default IntegrationWizardHeader;