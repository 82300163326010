import React from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { GetLanguageId,Translate } from "../Translator";

import { ConfigurationApiCallFieldScriptsDelete, ConfigurationApiCallFieldScriptsEnable } from "../../ExternalApi/ConfigurationApi.js";

import IntegrationWizardStepHeader from "../IntegrationWizard/IntegrationWizardStepHeader.jsx";

//Has all callfield scripts and the selected index instead of just the script
//This is so we can update the defaultscripts integration from here

const MainCallFieldScript = (props) => {

    const mHistory = useHistory();

    const GetPredefinedScriptsDescription = (aScriptName) => {
        const Script = props.PredefinedScripts.find(script => script.Id === aScriptName)
        if (Script !== undefined)
        {               
            return Script.Subheader["default"]
        }
        return "Script does not exist (yet) for the Web Configuration Tool";
    }

    const CallFieldScriptDelete = () => {
        if (props.SecurityToken)
        {
            ConfigurationApiCallFieldScriptsDelete(props.SecurityToken, props.SelectedIndex).then(result => props.OnIntegrationsChanged(result))
        }       
    }

    const CallFieldScriptEnable = async (aFlag) => {
        if (props.SecurityToken)
        {
            ConfigurationApiCallFieldScriptsEnable(props.SecurityToken, props.SelectedIndex, aFlag).then(result => props.OnIntegrationsChanged(result))
        }
      };

    const SelectedScript = props.Scripts[props.SelectedIndex]
    const SelectedScriptDescription = SelectedScript.XmlFile !== "" ? GetPredefinedScriptsDescription(SelectedScript.XmlFile) :
        SelectedScript.ScriptType === "OpenWebPage" ? Translate("Open webpage") + " " + SelectedScript.WebPage : 
          SelectedScript.ScriptType === "OpenApplication" ? Translate("Open application") + " " + SelectedScript.ApplicationExePath : 
              SelectedScript.ScriptType === "FindApplication" ? Translate("Perform an action in application") + " " + SelectedScript.ApplicationExePath + (SelectedScript.ApplicationCaption !== "" ? " " + Translate("with caption") + " " + SelectedScript.ApplicationCaption : "") :
                  SelectedScript.ScriptType === "CSD" ? Translate("Execute script") + ": " + SelectedScript.CSDFileName : 
                      SelectedScript.ScriptType === "ServerCSD" ? Translate("The server will automatically execute script") + ": " + SelectedScript.CSDFileName : "Invalid script";

    return (
        <span>
        <IntegrationWizardStepHeader Header={SelectedScript.XmlApplication ? SelectedScript.XmlApplication : SelectedScript.Name}/>
  
        <table width="100%" cellPadding={16}>
        <tbody>
        <tr>
          <td width="200" className="mainintegrationleftcolumn" valign="top">{Translate("Script settings")}</td>
          <td width="*" className="mainintegrationmiddlecolumn" valign="top">
            {SelectedScriptDescription}
            {SelectedScript.Keystrokes.length > 0 ? <div>[+ {SelectedScript.Keystrokes.length} keystrokes]</div> : ""} 
            {SelectedScript.Enabled ? "" : <div><b>{Translate("This script is disabled")}</b></div>}
          </td>
          <td width="100" className="mainintegrationrightcolumn" valign="top">
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => props.OnOpenPopupFullscreen("/ScriptWizard?Action=update&After=&SecurityToken=" + props.SecurityToken + "&languageid=" + GetLanguageId() + "&scriptindex=" + props.SelectedIndex)}>{Translate("Configure")}</Button> 
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => CallFieldScriptEnable(!SelectedScript.Enabled)}>{SelectedScript.Enabled ? Translate("Disable") : Translate("Enable")}</Button>   
          </td>
        </tr>
        <tr>
          <td/>
          <td/>
          <td colSpan={3} valign="top">
            <Button variant="dark" className="addnewbutton" onClick={() => CallFieldScriptDelete()}>{Translate("DELETE")}</Button> 
          </td>
        </tr>      
        </tbody>
        </table>
      </span>
      
    );
}

MainCallFieldScript.propTypes = {
  SecurityToken: PropTypes.string,
  Scripts: PropTypes.array,
  SelectedIndex: PropTypes.number, //Om de een of andere bizarre reden geeft 'number' foutmeldingen dat het een string is terwijl het zeker een number is
  PredefinedScripts: PropTypes.array,
  OnIntegrationsChanged: PropTypes.func
}

export default MainCallFieldScript